import React, { Component } from 'react';
import { Navbar, NavDropdown, Nav, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
import Cart from './Cart';
import { Link } from 'react-router-dom';

const mobileWidth =991;
class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCartVisible: false,
            sideBarToggle: false,
            width: window.innerWidth,
            navExpanded: false
        }
        this.toggleCart = this.toggleCart.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    toggle = () => {
        this.setState({ sideBarToggle: !this.state.sideBarToggle });
    }

    toggleCart() {
        this.setState({ isCartVisible: !this.state.isCartVisible });
    };

    setNavExpanded = () => {
        this.setState({ navExpanded: !this.state.navExpanded });
    }
    
    closeNav = () => {
        this.setState({ navExpanded: false });
    }

    /*  {!isCartVisible ? (
                            <button className="">
                                <FontAwesomeIcon size="2x" icon="shopping-cart" color="#292B83" />
                                {cart !== null ? <span>{cart.total_items}</span> : ''}
                            </button>
                        ) : (
                                <button className="">
                                    <FontAwesomeIcon size="2x" icon="times" color="#292B83" />
                                </button>
                            )} */
    renderCartNav() {
        const { cart } = this.props;
        const { isCartVisible } = this.state

        return (
            <div className="">
                <Link to="/cart">
                    <div className="nav__cart">
                        <button className="" onClick={this.closeNav}>
                            <FontAwesomeIcon size="2x" icon="shopping-cart" color="#ff8c00" />
                            {cart !== null ? <span>{cart.total_items}</span> : ''}
                        </button>
                    </div>
                </Link>
            </div>
        )
    }

    renderCategories() {
        if (this.state.width <= mobileWidth) {
            return (
                <>
                    <div className="caption-lg">DOGS</div>
                    <div className="fixedmenu menu-items">
                        <Nav.Link href="/#/all" onClick={this.closeNav}>ALL</Nav.Link>
                        <Nav.Link href="/#/categories/treats" onClick={this.closeNav}>TREATS</Nav.Link>
                        <Nav.Link href="/#/categories/collars" onClick={this.closeNav}>LEASES + COLLARS</Nav.Link>
                        <Nav.Link href="/#/" onClick={this.closeNav}>BED</Nav.Link>
                        <Nav.Link href="/#/categories/clothing" onClick={this.closeNav}>CLOTHING</Nav.Link>
                    </div>
                    <div className="caption-lg">CATS</div>
                    <div className="fixedmenu menu-items">
                        <Nav.Link href="/" onClick={this.closeNav}>ALL</Nav.Link>
                        <Nav.Link href="/" onClick={this.closeNav}>TREATS</Nav.Link>
                        <Nav.Link href="/" onClick={this.closeNav}>LEASES + COLLARS</Nav.Link>
                        <Nav.Link href="/" onClick={this.closeNav}>BED</Nav.Link>
                        <Nav.Link href="/" onClick={this.closeNav}>CLOTHING</Nav.Link>
                    </div>
                </>
            )
        }
    }

    renderOuterCart() {
        const {
            cart,
            categories,
            onUpdateCartQty,
            onRemoveFromCart,
            onEmptyCart } = this.props;
        if (this.state.width <= mobileWidth) {
            return (
                <>
                    <div className="mobileIcon">
                        <Link to="/" onClick={this.closeNav}>BowWow</Link>
                    </div>
                    <div className="mobileCart">
                        { this.renderCartNav()}
                    </div>
                </>
            )
        }
    }

    render() {
        const {
            cart,
            categories,
            onUpdateCartQty,
            onRemoveFromCart,
            onEmptyCart } = this.props;
        return (
            <div className="navBar top-nav-title">
                <Navbar expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {/*<Form inline>
                            <FormControl type="text" placeholder="Search" className="form-control mr-sm-2" />
                            <Link to="/">
                                <button>
                                    <FontAwesomeIcon size="2x" icon="search" color="#292B83" />
                                </button>
                            </Link>
                        </Form>*/}
                        {this.renderCategories()}
                        {this.state.width > mobileWidth &&
                            <>
                                <Nav className="mr-auto">
                                    <Nav.Link href="/">BowWow</Nav.Link>
                                </Nav>
                                {this.renderCartNav()}
                            </>
                        }
                    </Navbar.Collapse>
                </Navbar>

                {this.renderOuterCart()}

            </div>
        )
    };
};
export default NavigationBar;

NavigationBar.propTypes = {
    cart: PropTypes.object,
    onUpdateCartQty: () => { },
    onRemoveFromCart: () => { },
    onEmptyCart: () => { },
    handleUpdateCartQty: PropTypes.func,
    history: PropTypes.object
}