import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductItem from './ProductItem';
import Pagination from 'react-paginating';

const perPage = 12;

class ProductsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        }
    }

    handlePageChange = (page, e) => {
        this.setState({
            currentPage: page
        });
        window.scrollTo(0, 0);
    };

    render() {
        const { products } = this.props;
        const { currentPage } = this.state;
        const pageCount = Math.ceil(products.length / Math.max(perPage, 1));
        console.log(products.length+" "+pageCount)
        if(products.length==0){
            return (<></>)
        }
        return (
            <>
                <div>
                    <div className="products" id="products">
                        {products.slice((currentPage-1)*perPage, currentPage*perPage).map((product) => (
                            <ProductItem
                                key={product.id}
                                product={product}
                                {...this.props}
                            />
                        ))}
                    </div>
                    <div className="pagify">
                        <Pagination
                            className="bg-red"
                            limit = {perPage}
                            total={products.length}
                            pageCount={pageCount}
                            currentPage={currentPage}>
                            {({
                                pages,
                                currentPage,
                                hasNextPage,
                                hasPreviousPage,
                                previousPage,
                                nextPage,
                                totalPages,
                                getPageItemProps
                            }) => (
                                <div>
                                    

                                    {hasPreviousPage && (
                                        <>
                                        <button
                                        {...getPageItemProps({
                                            pageValue: 1,
                                            className: "normal",   
                                            onPageChange: this.handlePageChange
                                        })}>
                                        first
                                        </button>
                                        <button
                                            {...getPageItemProps({
                                                pageValue: previousPage,
                                                className: "page-btn",   
                                                onPageChange: this.handlePageChange
                                            })}
                                        >
                                            {'<'}
                                        </button>
                                        </>
                                    )}

                                    {pages.map(page => {
                                        let activePage = null;
                                        if (currentPage === page) {
                                            activePage = "highlight";
                                        }else{
                                            activePage = "normal";
                                        }
                                        return (
                                            <button
                                                {...getPageItemProps({
                                                    pageValue: page,
                                                    key: page,
                                                    className: activePage,                                  
                                                    onPageChange: this.handlePageChange
                                                })}
                                            >
                                                {page}
                                            </button>
                                        );
                                    })}

                                    {hasNextPage && (
                                        <>
                                        <button
                                            {...getPageItemProps({
                                                pageValue: nextPage,
                                                className: "page-btn",   
                                                onPageChange: this.handlePageChange
                                            })}
                                        >
                                            {'>'}
                                        </button>
                                        <button
                                        {...getPageItemProps({
                                            pageValue: totalPages,
                                            className: "normal",  
                                            onPageChange: this.handlePageChange
                                        })}
                                    >
                                        last
                                        </button>
                                    </>
                                    )}

                                    
                                </div>
                            )}
                        </Pagination>
                    </div>
                </div>
            </>
        )
    }
}

export default ProductsList;

ProductsList.propTypes = {
    products: PropTypes.array,
    handleAddToCart: PropTypes.func,
    onAddToCart: PropTypes.func,
};