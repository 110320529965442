import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartItem from '../components/CartItem';
import { Link } from "react-router-dom";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyClicked: false
    };
    this.handleEmptyCart = this.handleEmptyCart.bind(this);
  }

  handleEmptyCart() {
    this.props.onEmptyCart();
  }

  renderEmptyCart() {
    const { cart } = this.props;
    if (cart.total_unique_items > 0) {
      return;
    }

    return (
      <p className="cart__none">
        You have no items in your shopping cart, start adding some!
      </p>
    );
  }

  handleShow = () => {
    this.setState({ emptyClicked: true });
  };

  handleHide = () => {
    this.setState({ emptyClicked: false });
  };



  renderCart() {
    const { cart } = this.props;
    if (cart.total_unique_items === 0) {
      return;
    }
    if (cart.line_items != null) {
      return (
        <>
          {cart.line_items.map(lineItem => (
            <CartItem
              item={lineItem}
              key={lineItem.id}
              {...this.props}
              className=""
            />
          ))}
          <div className="product-name-price-cart d-flex justify-content-between align-items-center px-4">
            <p className="title-4 m-0">Subtotal:</p>
            <p className="caption-sm">{cart.subtotal.formatted_with_symbol}</p>
          </div>
          <div className="modal-buttons-cart">
            <button type="button" className="cart__btn-empty button-mobile-width" onClick={this.handleEmptyCart}>Empty cart</button>
            <Link className=" button-mobile-width" to="/checkout" >
              <button className="primary-btn button-mobile-width"> Checkout </button>
            </Link>
          </div>
        </>
      );
    }
    else {
      return (
        <div></div>
      );
    }
  }

  render() {
    return (
      <div className="cart">
        <div className="caption-lg text-center shopping-cart-text">Your Shopping Cart</div>
        <div className="subtotal-and-buttons">
          {this.renderEmptyCart()}
          {this.renderCart()}
        </div>
      </div>
    );
  };
};

export default Cart;

Cart.propTypes = {
  cart: PropTypes.object,
  onUpdateCartQty: () => { },
  onRemoveFromCart: () => { },
  onEmptyCart: () => { },
  handleUpdateCartQty: PropTypes.func,
  history: PropTypes.object
};
