import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'


class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeClicked: false
    };
    this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
  }

  handleShow = () => {
    this.setState({removeClicked: true});
  };

  handleHide = () => {
    this.setState({removeClicked: false});
  };

  handleUpdateCartQty(lineItemId, quantity) {
    this.props.onUpdateCartQty(lineItemId, quantity);
  }

  handleRemoveFromCart(lineItemId) {
    this.props.onRemoveFromCart(lineItemId);
  }

  getOptions = (number) =>
  _.times(number, (index) => ({
      key: index,
      text: `${index + 1}`,
      value: index + 1,
  }))

  handleDropdown = (event, data) => {
    const { item } = this.props;
    this.props.onUpdateCartQty(item.id, data.value);
  };

  render() {
    const { item } = this.props;
    return (
      <div className="d-flex justify-content-between align-items-center mb-4 cart-container product-container-cart">
        <div className="d-flex align-items-center">
          <a href={`/item/${item.permalink}`}>
            <img className="" src={item.media.source} alt={item.name} width="100" height="100"/>
          </a>
          {/* Product Info */}
            <div className="margin-left-sm dropdown-mobile-width">
              <a href={`/item/${item.permalink}`}>
                <h4 className="title-4">{item.name}</h4>
              </a>
              {item.variants.map(variant => (
                <div className="d-flex align-items-center pb-1 mt-2">
                  <p className="caption-sm m-0 pr-1">{variant.variant_name}:</p> 
                  <p className="body pb-1"> {variant.option_name}</p> 
                </div>

              ))}
              {/* Add quantity buttons  */}
              <div className="d-flex">
                <button type="button" className="quantity-btn" onClick={() => item.quantity > 1 ? this.handleUpdateCartQty(item.id, item.quantity - 1) : this.handleRemoveFromCart(item.id)}>-</button>
                <p className="body quantity-number">{item.quantity}</p>
                <button type="button" className="quantity-btn" onClick={() => this.handleUpdateCartQty(item.id, item.quantity + 1)}>+</button>
              </div>
             
                {/* <div className="dropdown">Qty: </div>
                <Dropdown
                  selection
                  defaultValue={item.quantity}
                  scrolling options={this.getOptions(99)}
                  onChange={this.handleDropdown} /> */}
          </div>
        </div>

        <div className="">
          {/* Remove Button */}
          <div>
            <button type="button" className="cart-item__remove " onClick={() => this.handleRemoveFromCart(item.id)}>Remove</button>
          </div>
          {/* Total for item */}
          <div className="caption-sm">
            {item.line_total.formatted_with_symbol}
          </div>
        </div>


  
      </div>
    );
  };
};

export default CartItem;

CartItem.propTypes = {
    item: PropTypes.object,
    handleUpdateCartQty: PropTypes.func,
    onUpdateCartQty: PropTypes.func,
    onRemoveFromCart: PropTypes.func
 };
