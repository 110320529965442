import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commerce } from '../lib/Commerce';
import ProductsList from '../components/ProductsList';

class Category extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slug: {},
            products: [],
            category: [],
          }

        this.state.slug = props.match.params.slug;
        this.state.category = commerce.categories.retrieve(this.state.slug,{
            type:"slug",});
        this.fetchProducts();
    };

    componentDidMount() {
        this.fetchProducts();
    }

    fetchProducts() {
        commerce.products.list({limit:200,category_slug: this.state.slug}).then((products) => {
            this.setState({ products: products.data });
        }); 
    }

    render(props) {
        const {
            slug,
            products,
            category,
        } = this.state;

        return (
            <div className="category">
                    <h2 className="caption-lg ml-5">{slug}</h2>
                    <ProductsList
                        {...props}
                        products={products}
                    />
            </div>
        );
    };
};

export default Category;

Category.propTypes = {
    cart: PropTypes.object,
    history: PropTypes.object,
    onCaptureCheckout: () => {},
};