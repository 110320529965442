import React, { Component } from "react";
import PropTypes from 'prop-types';

class ProductItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: ''
        }
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    handleAddToCart() {
        this.props.onAddToCart(this.props.product.id, 1);
    }

    checkQuanity(product){
        console.log(product)
        if(product.variants.length>0)
        {
            for (var i = 0;i<product.variants[0].options.length;i++){
                if(product.variants[0].options[i].quantity>0){
                    console.log(product.variants[0].options[i].quantity)
                    return 1
                }
            }
            return 0;
        } else
            return product.quantity;
    }

    render() {
        const { product } = this.props
        var price = ''
        const quantity = this.checkQuanity(product)
        if(quantity===0){
            price = "OUT OF STOCK"
        }
        else if(product.variants.length>0){
            if(product.variants[0].options[0].price.raw !== product.variants[0].options[product.variants[0].options.length-1].price.raw){
                price = product.variants[0].options[0].price.formatted_with_symbol + " - " + product.variants[0].options[product.variants[0].options.length-1].price.formatted_with_symbol
            }
            else
                price = product.variants[0].options[0].price.formatted_with_symbol
        }
        else
            price = product.price.formatted_with_symbol
        return (
            <div className="item-container">
                <div className="product-container-listing">
                    <a href={`/#/item/${product.permalink}`}>
                        <img className="product__image" src={product.media.source} alt={product.name} />
                    </a>
                </div >
                <div className="section-separator"></div>
                <div className="product-name-price-list">
                    <div>
                        <h4 className="title-4 mb-2">{product.name}</h4>
                    </div>
                    <div className="">
                        <p className="caption-sm">
                            {price}
                        </p>
                    </div>
                </div>
            </div> 

        );
    }
};


export default ProductItem;

ProductItem.propTypes = {
    product: PropTypes.object,
    onAddToCart: PropTypes.func,
    handleAddToCart: PropTypes.func,
};