import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commerce } from '../lib/Commerce';
import { Link } from "react-router-dom";
import { Button } from 'bootstrap';
import { delay } from 'lodash';

class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkoutToken: {},
            // Customer details
            firstName: '',
            lastName: '',
            email: '',
            // Shipping details
            shippingName: '',
            shippingStreet: '',
            shippingCity: '',
            shippingStateProvince: '',
            shippingPostalZipCode: '',
            shippingCountry: 'US',
            // Payment details
            cardName: '',
            cardNum: '',
            expMonth: '',
            expYear: '',
            ccv: '',
            billingPostalZipcode: '',
            // Shipping and fulfillment data
            shippingCountries: {},
            shippingSubdivisions: {},
            shippingOptions: [],
            shippingOption: '',

            formSelection: 0,

            firstNameFlag: false,
            lastNameFlag: false,
            emailFlag: false,
            shippingStreetFlag: false,
            shippingCityFlag: false,
            shippingPostalZipCodeFlag: false,

            cardNameFlag: false,
            cardNumFlag: false,
            expMonthFlag: false,
            expYearFlag: false,
            ccvFlag: false,
            billingPostalZipcodeFlag: false,

            canCheckout: false,
            processingCheckout: false
        }

        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleShippingCountryChange = this.handleShippingCountryChange.bind(this);
        this.handleCaptureCheckout = this.handleCaptureCheckout.bind(this);
    };

    componentDidMount() {
        const { cart } = this.props;
        if (cart.line_items != null)
            this.generateCheckoutToken().then(() => this.fetchSubdivisions(this.state.shippingCountry));
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shippingCountry !== prevState.shippingCountry) {
            this.fetchShippingOptions(this.state);
        }
    };

    /**
     *  Generates a checkout token
     *  https://commercejs.com/docs/sdk/checkout#generate-token
     */
    generateCheckoutToken() {
        const { cart } = this.props;
        if (cart.line_items.length) {
            return commerce.checkout.generateToken(cart.id, { type: 'cart' })
                .then((token) => this.setState({ checkoutToken: token }))
                .then(() => this.fetchShippingCountries(this.state.checkoutToken.id))
                .then(() => this.fetchShippingOptions(this.state.checkoutToken.id, this.state.shippingCountry, this.state.shippingStateProvince))
                .catch((error) => {
                    console.log('There was an error in generating a token', error);
                });
        }
    };

    checkVariant(checkoutToken) {
        for (let item of checkoutToken.live.line_items) {
            if (item.variants.length > 0) {
                for (let variant of item.variants) {
                    commerce.checkout.checkVariant(checkoutToken.id, item.id, {
                        variant_id: variant.variant_id,
                        option_id: variant.option_id,
                    }).then(response => console.log(response.available))
                }
            }
        }

    }

    /**
     * Fetches a list of countries available to ship to checkout token
     * https://commercejs.com/docs/sdk/checkout#list-available-shipping-countries
     *
     * @param {string} checkoutTokenId
     */
    fetchShippingCountries(checkoutTokenId) {
        commerce.services.localeListShippingCountries(checkoutTokenId).then((countries) => {
            this.setState({
                shippingCountries: countries.countries,
            })
        }).catch((error) => {
            console.log('There was an error fetching a list of shipping countries', error);
        });
    };

    /**
     * Fetches the subdivisions (provinces/states) for a country
     * https://commercejs.com/docs/sdk/checkout#list-all-subdivisions-for-a-country
     *
     * @param {string} countryCode
     */
    fetchSubdivisions(countryCode) {
        commerce.services.localeListSubdivisions(countryCode).then((subdivisions) => {
            this.setState({
                shippingSubdivisions: subdivisions.subdivisions,
            })
        }).catch((error) => {
            console.log('There was an error fetching the subdivisions', error);
        });
    };

    /**
     * Fetches the available shipping methods for the current checkout
     * https://commercejs.com/docs/sdk/checkout#get-shipping-methods
     *
     * @param {string} checkoutTokenId
     * @param {string} country
     * @param {string} stateProvince
     */
    fetchShippingOptions(checkoutTokenId, country, stateProvince = null) {
        commerce.checkout.getShippingOptions(checkoutTokenId,
            {
                country: country,
                region: stateProvince
            }).then((options) => {
                // Pre-select the first available method
                const shippingOption = options[0] || null;
                this.setState({
                    shippingOption: shippingOption,
                    shippingOptions: options
                })
            }).catch((error) => {
                console.log('There was an error fetching the shipping methods', error);
            });
    };

    handleFormChanges(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
        /// SHIPPING INFO 
        if(e.target.name==="email"&&e.target.value.length>0){
            this.setState({emailFlag: false});
        }
        if(e.target.name==="firstName"&&e.target.value.length>0){
            this.setState({firstNameFlag: false});
        }
        if(e.target.name==="lastName"&&e.target.value.length>0){
            this.setState({lastNameFlag: false});
        }
        if(e.target.name==="shippingStreet"&&e.target.value.length>0){
            this.setState({shippingStreetFlag: false});
        }
        if(e.target.name==="shippingCity"&&e.target.value.length>0){
            this.setState({shippingCityFlag: false});
        }
        if(e.target.name==="shippingPostalZipCode"&&e.target.value.length>0){
            this.setState({shippingPostalZipCodeFlag: false});
        }

        /// CREDIT CARD CHECKER
        if (this.state.formSelection !== 2) {
            return;
        }

        if(e.target.name==="cardNum"&&e.target.value.length>0){
            this.setState({cardNumFlag: false});
        }
        if(e.target.name==="cardName"&&e.target.value.length>0){
            this.setState({cardNameFlag: false});
        }
        if(e.target.name==="expMonth"&&e.target.value.length>0){
            this.setState({expMonthFlag: false});
        }
        if(e.target.name==="expYear"&&e.target.value.length>0){
            this.setState({expYearFlag: false});
        }
        if(e.target.name==="ccv"&&e.target.value.length>0){
            this.setState({ccvFlag: false});
        }
        if(e.target.name==="billingPostalZipcode"&&e.target.value.length>0){
            this.setState({billingPostalZipcodeFlag: false});
        }
        if(this.state.cardNum===""||this.state.cardNum.length<19||this.state. cardName===""||this.state.cardNum.length<2||this.state.expMonth===""||
        this.state.expYear===""||this.state.expYear.length<4||this.state.ccv===""||this.state.billingPostalZipcode===""||this.state.ccv.length<3){
            this.setState({
                canCheckout : false
            });
        }else{
            this.setState({
                canCheckout : true
            });
        }

        // FIX CREDIT CARD INFO
        var allowedChars = "0123456789";
        var entryVal = e.target.value;
        var flag;

        if(e.target.name === "cardNum") {
            for (var i = 0; i < entryVal.length; i++) {
                flag = false;

                for (var j = 0; j < allowedChars.length && !flag; j++) {
                    if (entryVal.charAt(i) == allowedChars.charAt(j)) {
                        flag = true;
                    }
                }

                if (flag == false) {
                    entryVal = entryVal.replace(entryVal.charAt(i), ""); i--;
                }
            }

            if (entryVal.length > 4 && entryVal.charAt(4) !== ' ') {
                var beginning = entryVal.substring(0, 4)
                var ending = entryVal.substring(4, entryVal.length)
                entryVal = beginning + " " + ending
            }
            if (entryVal.length > 9 && entryVal.charAt(9) !== ' ') {
                var beginning = entryVal.substring(0, 9)
                var ending = entryVal.substring(9, entryVal.length)
                entryVal = beginning + " " + ending
            }
            if (entryVal.length > 14 && entryVal.charAt(14) !== ' ') {
                var beginning = entryVal.substring(0, 14)
                var ending = entryVal.substring(14, entryVal.length)
                entryVal = beginning + " " + ending
            }

            this.setState({
                cardNum: entryVal
            });
        }
        else if(e.target.name === "cardNum") {
            for (var i = 0; i < entryVal.length; i++) {
                flag = false;

                for (var j = 0; j < allowedChars.length && !flag; j++) {
                    if (entryVal.charAt(i) == allowedChars.charAt(j)) {
                        flag = true;
                    }
                }

                if (flag == false) {
                    entryVal = entryVal.replace(entryVal.charAt(i), ""); i--;
                }
            }

            if (entryVal.length > 4 && entryVal.charAt(4) !== ' ') {
                var beginning = entryVal.substring(0, 4)
                var ending = entryVal.substring(4, entryVal.length)
                entryVal = beginning + " " + ending
            }
            if (entryVal.length > 9 && entryVal.charAt(9) !== ' ') {
                var beginning = entryVal.substring(0, 9)
                var ending = entryVal.substring(9, entryVal.length)
                entryVal = beginning + " " + ending
            }
            if (entryVal.length > 14 && entryVal.charAt(14) !== ' ') {
                var beginning = entryVal.substring(0, 14)
                var ending = entryVal.substring(14, entryVal.length)
                entryVal = beginning + " " + ending
            }

            this.setState({
                cardNum: entryVal
            });
        } else if(e.target.name === "expMonth" || e.target.name === "expYear"|| e.target.name === "ccv"||e.target.name === "billingPostalZipcode") {
            for (var i = 0; i < entryVal.length; i++) {
                flag = false;

                for (var j = 0; j < allowedChars.length && !flag; j++) {
                    if (entryVal.charAt(i) == allowedChars.charAt(j)) {
                        flag = true;
                    }
                }

                if (flag == false) {
                    entryVal = entryVal.replace(entryVal.charAt(i), ""); i--;
                }
            }
            this.setState({
                [e.target.name]: entryVal
            });
        }
    };

    changeFormSection(part) {
        if(!this.checkInformationFilled()){
            return
        }
        window.scrollTo(0, 0);
        this.setState({
            formSelection: part
        });
    };

    checkInformationFilled(){
        this.setState({
            emailFlag : (this.state.email==="")
        });
        this.setState({
            firstNameFlag : (this.state.firstName==="")
        });
        this.setState({
            lastNameFlag : (this.state.lastName==="")
        });
        this.setState({
            shippingStreetFlag : (this.state.shippingStreet==="")
        });
        this.setState({
            shippingCityFlag : (this.state.shippingCity==="")
        });
        this.setState({
            shippingPostalZipCodeFlag : (this.state.shippingPostalZipCode==="")
        });
        if(this.state.email===""||this.state.firstName===""||this.state.lastName===""||
        this.state.shippingStreet===""||this.state.shippingCity===""||this.state.shippingPostalZipCode==="")
            return false
        return true
    }

    checkCreditCardFilled(){
        this.setState({
            cardNumFlag : (this.state.cardNum===""||this.state.cardNum.length<19)
        });
        this.setState({
            cardNameFlag : (this.state.cardName==="")
        });
        this.setState({
            expMonthFlag : (this.state.expMonth===""||this.state.cardNum.length<2)
        });
        this.setState({
            expYearFlag : (this.state.expYear===""||this.state.expYear.length<4)
        });
        this.setState({
            ccvFlag : (this.state.ccv===""||this.state.ccv.length<3)
        });
        this.setState({
            billingPostalZipcodeFlag : (this.state.billingPostalZipcode==="")
        });
        if(this.state.cardNum===""||this.state.cardNum.length<19||this.state. cardName===""||this.state.cardNum.length<2||this.state.expMonth===""||
        this.state.expYear===""||this.state.expYear.length<4||this.state.ccv===""||this.state.billingPostalZipcode===""||this.state.ccv.length<3){
            this.setState({
                canCheckout : false
            });
            return false
        }
        else{
            this.setState({
                canCheckout : true
            });
        }
        return true
    }

    goToShipping() {
        if(!this.checkInformationFilled())
            return
        window.scrollTo(0, 0);
        this.setState({
            formSelection: 1
        });
    };

    handleShippingCountryChange(e) {
        const currentValue = e.target.value;
        this.fetchSubdivisions(currentValue);
    };

    handleCaptureCheckout(e) {
        this.setState({
            processingCheckout : true
        });
        if(!this.state.canCheckout)
            return
        this.setState({
            canCheckout : false
        });
        e.preventDefault();
        const orderData = {
            line_items: this.populateOrderItems(this.state.checkoutToken.live.line_items),
            customer: {
                firstname: this.state.firstName,
                lastname: this.state.lastName,
                email: this.state.email
            },
            shipping: {
                name: this.state.cardName,
                street: this.state.shippingStreet,
                town_city: this.state.shippingCity,
                county_state: this.state.shippingStateProvince,
                postal_zip_code: this.state.shippingPostalZipCode,
                country: this.state.shippingCountry,
            },
            fulfillment: {
                shipping_method: this.state.shippingOption.id
            },
            billing: {
                name: this.state.cardName,
                street: this.state.shippingStreet,
                town_city: this.state.shippingCity,
                county_state: this.state.shippingStateProvince,
                postal_zip_code: this.state.shippingPostalZipCode,
                country: this.state.shippingCountry,
            },
            payment: {
                gateway: "test_gateway",
                card: {
                    number: this.state.cardNum,
                    expiry_month: this.state.expMonth,
                    expiry_year: this.state.expYear,
                    cvc: this.state.ccv,
                    postal_zip_code: this.state.billingPostalZipcode
                }
            }
        };
        this.props.onCaptureCheckout(this.state.checkoutToken.id, orderData);
       // this.props.history.push('/confirmation');
    };

    populateOrderItems(ITEMS){
        //this.state.checkoutToken.live.line_items
        var lineItems= {}
        for(let item of ITEMS){
            var variants= {}
            for(let variant of item.variants){
                variants[variant.variant_id]=variant.option_id
            }
            lineItems[item.id]={quantity: item.quantity,variants: variants}
        }
        return lineItems
        /*
        {
            item_7RyWOwmK5nEa2V: {
              quantity: 1,
              variants: {
                vrnt_bO6J5apWnVoEjp: 'optn_Op1YoVppylXLv9',
                vrnt_4WJvlKpg7pwbYV: 'optn_zkK6oL99G5Xn0Q',
              }
            },
            item_7RyWOwmK5nEa2V: {
                quantity: 1,
                variants: {
                  vrnt_bO6J5apWnVoEjp: 'optn_Op1YoVppylXLv9',
                  vrnt_4WJvlKpg7pwbYV: 'optn_zkK6oL99G5Xn0Q',
                }
            }
        }
        */
    }

    renderBreadCrumbs() {
        if (this.state.formSelection === 0) {
            return (
            <div className="checkout-margin d-flex align-items-center justify-content-center">
                <Link to="/cart" >
                    <button className="bttn">Cart /</button>
                </Link>
                <div className="active-breadcrumb"> Information </div>
                <span className="inactive-breadcrumb"> /</span>
                <button className="inactive-breadcrumb" onClick={() => this.changeFormSection(1)}> Shipping /</button>
                <div className="inactive-breadcrumb"> Payment</div>
            </div>
            )
        }
        else if (this.state.formSelection === 1) {
            return (
                <div className="checkout-margin d-flex align-items-center justify-content-center">
                    <Link to="/cart" >
                        <button className="bttn">Cart /</button>
                    </Link>
                    <button className="bttn" onClick={() => this.changeFormSection(0)}> Information /</button>
                    <div className="active-breadcrumb"> Shipping</div>
                    <button className="inactive-breadcrumb" onClick={() => this.changeFormSection(2)}> / Payment</button>
                </div>
                )
        }
        else if (this.state.formSelection === 2) {
            return (
                <div className="checkout-margin d-flex align-items-center justify-content-center">
                    <Link to="/cart" >
                        <button className="bttn">Cart /</button>
                    </Link>
                    <button className="bttn" onClick={() => this.changeFormSection(0)}> Information /</button>
                    <button className="bttn" onClick={() => this.changeFormSection(1)}> Shipping /</button>
                    <div className="active-breadcrumb"> Payment</div>
                </div>
                )
        }
    }

    renderInformation() {
        const { cart } = this.props;
        const { shippingCountries, shippingSubdivisions, shippingOptions, formSelection, checkoutToken } = this.state;
        if (formSelection !== 0) {
            return;
        }
        if (cart.line_items != null && checkoutToken.live == null) {
            this.generateCheckoutToken().then(() => this.fetchSubdivisions(this.state.shippingCountry));
        }
        return (
            <>
                <div className="checkout-form">
                    <form onChange={this.handleFormChanges}>
                        <div className="caption-lg ml-2">Contact information</div>
                        <div className="email-form mx-2">
                            <label className="label" htmlFor="email">Email</label>
                             <input className={"input "+(this.state.emailFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.email} name="email" placeholder="Email" required />
                             {this.state.emailFlag&&
                                <div className="emptyWarning">Please enter a valid email</div>} 
                        </div>


                        <div className="caption-lg margin-top-md ml-2">Shipping details</div>
                            <div className="checkout-input-wrapper">
                                    <div className="mx-2 input-container">
                                        <div><label className="label" htmlFor="firstName">First name</label></div>
                                        <div><input className={"input "+(this.state.firstNameFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.firstName} name="firstName" placeholder="First name" required /></div>
                                        {this.state.firstNameFlag&&
                                            <div className="emptyWarning">Please enter your first name</div>} 
                                    </div>
                                    <div className="mx-2 input-container">
                                        <div><label className="label" htmlFor="lastName">Last name</label></div>
                                        <div><input className={"input "+(this.state.lastNameFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.lastName} name="lastName" placeholder="Last name" required /></div>
                                        {this.state.lastNameFlag&&
                                            <div className="emptyWarning">Please enter your last name</div>} 
                                    </div>
                            </div>

                            <div className="checkout-input-wrapper">
                                <div className="mx-2 input-container">
                                    <div><label className="label" htmlFor="shippingStreet">Street address</label></div>
                                    <div><input className={"input "+(this.state.shippingStreetFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.shippingStreet} name="shippingStreet" placeholder="Address" required /></div>
                                    {this.state.shippingStreetFlag&&
                                        <div className="emptyWarning">Please enter a valid street address</div>} 
                                </div>
                                <div className="mx-2 input-container">
                                    <div><label className="label" htmlFor="shippingCity">City</label></div>
                                    <div><input className={"input "+(this.state.shippingCityFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.shippingCity} name="shippingCity" placeholder="City" required /></div>
                                    {this.state.shippingCityFlag&&
                                        <div className="emptyWarning">Please enter a valid city</div>} 
                                </div>
                                <div className="mx-2 input-container">
                                    <div><label className="label" htmlFor="shippingPostalZipCode">Postal/Zip code</label></div>
                                    <div><input className={"input "+(this.state.shippingPostalZipCodeFlag ? "error":"")} type="text" onChange={this.handleFormChanges} value={this.state.shippingPostalZipCode} name="shippingPostalZipCode" placeholder="ZIP code" required /></div>
                                    {this.state.shippingPostalZipCodeFlag&&
                                        <div className="emptyWarning">Please enter a valid zipcode</div>} 
                                </div>
                            </div>

                            <div className="checkout-input-wrapper">
                                <div className="mx-2 input-container">
                                <label className="label" htmlFor="shippingCountry">Country</label>
                                <select
                                    value={this.state.shippingCountry}
                                    name="shippingCountry"
                                    onChange={this.handleShippingCountryChange}
                                    className="checkout__select"
                                >
                                    <option disabled>Country</option>
                                    {
                                        Object.keys(shippingCountries).map((index) => {
                                            return (
                                                <option value={index} key={index}>{shippingCountries[index]}</option>
                                            );
                                        })
                                    };
                                </select>
                            </div>
                            <div role="listbox" className="mx-2 title-4 input-container">
                                <label className="label" htmlFor="shippingStateProvince">State/province</label>
                                <select
                                    value={this.state.shippingStateProvince}
                                    name="shippingStateProvince"
                                    onChange={this.handleFormChanges}
                                    className="checkout__select"
                                >
                                    <option className="checkout__option" disabled>State/province</option>
                                    {
                                        Object.keys(shippingSubdivisions).map((index) => {
                                            return (
                                                <option value={index} key={index}>{shippingSubdivisions[index]}</option>
                                            );
                                        })
                                    };

                                </select>
                            </div>
                            
                        </div>

                    </form>
                    <div className="button-wrapper-checkout">
                        <div className="modal-buttons-checkout">
                            <Link to="/cart" >
                                <button className="ml-2 button-mobile-width bttn">Return to cart</button>
                            </Link>
                            <button className="primary-btn button-mobile-width" onClick={() => this.goToShipping()}>Continue to shipping</button>
                            </div>
                        </div>
                    </div>
            </>
        );
    }

    renderShippingInfo() {
        const { shippingCountries, shippingSubdivisions, shippingOptions, formSelection } = this.state;
        if (formSelection !== 1) {
            return;
        }
        return (
            <div className="checkout-form">
                <div className="mx-2">
                    <form onChange={this.handleFormChanges}>
                        <h4 className="caption-lg"></h4>
                        <div className="d-flex">
                            <p className="body">Name:</p>
                            <p className="title-4 pl-1"> {this.state.firstName} {this.state.lastName}</p>
                        </div>
                        <div className="d-flex">
                            <p className="body">Contact: </p>
                            <p className="title-4 pl-1">{this.state.email}</p>
                        </div>
                        <div className="d-flex">
                            <p className="body">Ship To: </p>
                            <p className="title-4 pl-1">
                                {this.state.shippingStreet} <br></br> {this.state.shippingCity} {this.state.shippingCountry} {this.state.shippingStateProvince} <br></br> {this.state.shippingPostalZipCode} 
                            </p>
                        </div>
                        <label className="checkout__label" htmlFor="shippingOption">Shipping method</label>
                        <select
                            value={this.state.shippingOption.id}
                            name="shippingOption"
                            onChange={this.handleFormChanges}
                            className="checkout__select">
                            <option className="checkout__select-option" disabled>Select a shipping method</option>
                            {
                                shippingOptions.map((method, index) => {
                                    return (
                                        <option className="checkout__select-option" value={method.id} key={index}>{`${method.description} - $${method.price.formatted_with_code}`}</option>
                                    );
                                })
                            };
                        </select>
                    </form>
                    <div className="modal-buttons-checkout">
                        <button className="bttn-return button-mobile-width" onClick={() => this.changeFormSection(0)}>Return to information</button>
                        <button className="primary-btn button-mobile-width" onClick={() => this.changeFormSection(2)}>Continue to payment</button>
                    </div>
                </div>


            </div>
        );
    }

    renderPayment() {
        const { shippingCountries, shippingSubdivisions, shippingOptions, formSelection } = this.state;
        if (formSelection !== 2) {
            return;
        }
        return (
            <div className="checkout-form">
                <form onChange={this.handleFormChanges}>
                    <div className="caption-lg ml-2">Payment information</div>
                        <div className="checkout-input-wrapper">
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="cardNum">Credit card number</label></div> 
                                <div><input className={"input "+(this.state.cardNumFlag ? "error":"")} type="text" id="cardNum" name="cardNum" onChange={this.handleFormChanges} value={this.state.cardNum} placeholder="Enter your card number" maxLength="19"/></div>
                                {this.state.cardNumFlag&&
                                    <div className="emptyWarning">Please enter a valid card number</div>} 
                            </div>
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="cardName">Name on card</label></div> 
                                <div><input className={"input "+(this.state.cardNameFlag ? "error":"")} type="text" name="cardName" onChange={this.handleFormChanges} value={this.state.cardName} placeholder="Enter name on card" maxLength="50"/></div>
                                {this.state.cardNameFlag&&
                                    <div className="emptyWarning">Please enter the name on the card</div>} 
                            </div>
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="expMonth">Expiration month</label></div>
                                <div><input className={"input "+(this.state.expMonthFlag ? "error":"")} type="text" name="expMonth" onChange={this.handleFormChanges} value={this.state.expMonth} placeholder="Card expiration month" maxLength="2"/></div>
                                {this.state.expMonthFlag&&
                                    <div className="emptyWarning">Please enter a valid expiration month</div>} 
                            </div>
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="expYear">Expiration year</label></div>
                                <div><input className={"input "+(this.state.expYearFlag ? "error":"")} type="text" name="expYear" onChange={this.handleFormChanges} value={this.state.expYear} placeholder="Card expiration year" maxLength="4"/></div>
                                {this.state.expYearFlag&&
                                    <div className="emptyWarning">Please enter a valid expiration year</div>} 
                            </div>
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="ccv">CCV</label></div>
                                <div><input className={"input "+(this.state.ccvFlag ? "error":"")} type="text" name="ccv" onChange={this.handleFormChanges} value={this.state.ccv} placeholder="CCV (3 digits)" maxLength="3"/></div>
                                {this.state.ccvFlag&&
                                    <div className="emptyWarning">Please enter a valid ccv (3 digits)</div>} 
                            </div>
                            <div className="mx-2 input-container">
                                <div><label className="label" htmlFor="ccv">Billing zipcode</label></div>
                                <div><input className={"input "+(this.state.billingPostalZipcodeFlag ? "error":"")} type="text" name="billingPostalZipcode" onChange={this.handleFormChanges} value={this.state.billingPostalZipcode} placeholder="Billing zipcode"/></div>
                                {this.state.billingPostalZipcodeFlag&&
                                    <div className="emptyWarning">Please enter a valid zipcode</div>} 
                            </div>
                        </div>
                        </form>
                        <div className="button-wrapper-checkout">  
                            <div className="modal-buttons-checkout">
                            <button className="button-mobile-width bttn-return" onClick={() => this.changeFormSection(1)}>Return to shipping</button>
                                {(this.state.processingCheckout ?
                                    <button className="primary-btn mr-0 mb-0 button-mobile-width">Processing Order</button>
                                : 
                                <>
                                    {(this.state.canCheckout ?
                                    <Link className="button-mobile-width" to="/confirmation">
                                        <button className="primary-btn mr-0 mb-0 button-mobile-width" onClick={this.handleCaptureCheckout}>Place order</button>
                                    </Link>
                                    :
                                    <button className="button-mobile-width primary-btn mr-0 mb-0 button-mobile-width" onClick={() => this.checkCreditCardFilled()}>Place order</button>
                                    )}
                                </>
                                )}
                            </div>
                        </div>
            </div>
        );
    }

    renderCheckoutSummary() {
        const { cart } = this.props;
        if (cart.line_items != null) {
            return (
                <>
                    <div className="order-summary">
                        <div className="m-4 p-2">
                            <div className="caption-lg">Order summary</div>
                            {cart.line_items.map((lineItem) => (
                                <>
                                    <div key={lineItem.id} className="d-flex align-items-start my-4">
                                        <img className="product-container-summary checkout__summary-img" src={lineItem.media.source} alt={lineItem.name} />
                                        <div className="checkout-name-price">
                                            <div className="title-4">{lineItem.name}
                                                <div>
                                                    {lineItem.variants.map(variant => (
                                                        <div className="d-flex align-items-center">
                                                            <div className="caption-sm">{variant.variant_name}: </div>
                                                            <div className="caption-xs ml-1">{variant.option_name} </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="d-flex mt-1">
                                                    <div className="caption-sm">qty: </div>
                                                    <div className="badge ml-1">{lineItem.quantity}</div>
                                                </div>
                                            </div>
                                            <div className="caption-sm">{lineItem.line_total.formatted_with_symbol}</div>
                                        </div>
                                    </div>

                                </>
                            ))}
                        </div>
                        <div className="section-separator"></div>
                        <div className="product-name-price-checkout">
                                <span className="title-4">Subtotal: </span>
                                <div className="caption-sm">
                                    {cart.subtotal.formatted_with_symbol}
                                </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <div></div>
            );
        }
    }

    render() {
        return (
            <div className="checkout checkout-margin">
                <h2 className="checkout-header mt-4 mb-0 checkout-margin">
                    Checkout
                </h2>
                {this.renderBreadCrumbs()}

                <div className="checkout__wrapper">
                    {this.renderInformation()}
                    {this.renderShippingInfo()}
                    {this.renderPayment()}
                    {this.renderCheckoutSummary()}
                </div>
            </div>
        );
    };
};

export default Checkout;

Checkout.propTypes = {
    cart: PropTypes.object,
    history: PropTypes.object,
    onCaptureCheckout: () => { },
};