import React, { Component } from 'react';

class SideNavBar extends Component {
    constructor(props) {
        super(props);
    }
    showSettings(event) {
        event.preventDefault();
    }

    moveBackUp(){
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="submenu">
                <div className="fixedmenu margin-left-sm">
                <a className="title-nav-cat"  href="/">DOGS</a>
                <a className="menu-item"  href="/#/all">ALL</a>
                <a className="menu-item" href="/#/categories/treats"  >TREATS</a>
                <a className="menu-item" href="/#/categories/collars"  >LEASES + COLLARS</a>
                <a className="menu-item"  href="/#/">BED</a>
                <a className="menu-item"  href="/#/categories/clothing">CLOTHING</a>
                <a className="title-nav-cat"  href="/">CATS</a>
                <a className="menu-item"  href="/">ALL</a>
                <a className="menu-item"  href="/">TREATS</a>
                <a className="menu-item"  href="/">LEASES + COLLARS</a>
                <a className="menu-item"  href="/">BED</a>
                <a className="menu-item"  href="/">CLOTHING</a>
                </div>
            </div>
        );
    }
}
export default SideNavBar;