import React, { Component } from 'react';
import { commerce } from './lib/Commerce';
import './styles/scss/styles.scss'
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingBag, faTimes, faSearch, faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons'
import { Switch, Route,withRouter   } from 'react-router-dom';
import PropTypes from 'prop-types';

import Hero from './components/Hero';
import CartPage from './pages/Cart';
import ProductsList from './components/ProductsList';
import Checkout from './pages/Checkout';
import Confirmation from './pages/Confirmation';
import Category from './pages/Category'
import Item from './pages/Item'
import NavigationBar from './components/NavBar';
import SideNavBar from './components/SideNavBar';
import CategoryList from './components/CategoryList';

library.add(faShoppingBag, faTimes, faSearch, faShoppingCart, faBars)

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      merchant: {},
      products: [],
      categories: [],
      cart: {},
      order: this.loadOrderFromLocalStorage() ?? {},
    }

    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleUpdateCartQty = this.handleUpdateCartQty.bind(this);
    this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
    this.handleEmptyCart = this.handleEmptyCart.bind(this);
    this.handleCaptureCheckout = this.handleCaptureCheckout.bind(this);
    this.refreshCart = this.refreshCart.bind(this);
    this.fetchCart();
    this.loadOrderFromLocalStorage();
  }

  componentDidMount() {
    this.fetchMerchantDetails();
    this.fetchProducts();
    this.fetchCategories();
    this.fetchCart();
    this.loadOrderFromLocalStorage();
  }

  /**
   * Fetch a saved order receipt from local storage so we can show the confirmation page
   * again between page refreshes.
   */
  loadOrderFromLocalStorage() {
    if (window.localStorage.getItem('order_receipt')) {
      return JSON.parse(window.localStorage.getItem('order_receipt'));
    }
  }


  /**
   * Fetch merchant details
   * https://commercejs.com/docs/sdk/full-sdk-reference#merchants
   */
  fetchMerchantDetails() {
    commerce.merchants.about().then((merchant) => {
      this.setState({ merchant: merchant });
    }).catch((error) => {
      console.log('There was an error fetch the merchant details', error)
    });
  }

  /**
   * Fetch products data from Chec and stores in the products data object.
   * https://commercejs.com/docs/sdk/products
   */
  fetchProducts() {
    commerce.products.list({limit:200}).then((products) => {
      this.setState({ products: products.data });
    }).catch((error) => {
      console.log('There was an error fetching the products', error);
    });
  }

  /**
   * Fetch categories data from Chec and stores in the categories data object.
   */
  fetchCategories() {
    commerce.categories.list().then((categories) => {
      this.setState({ categories: categories.data });
    }).catch((error) => {
      console.log('There was an error fetching categories', error);
    });
  }

  /**
   * Retrieve the current cart or create one if one does not exist
   * https://commercejs.com/docs/sdk/cart
   */
  fetchCart() {
    commerce.cart.retrieve().then((cart) => {
      this.setState({ cart: cart });
    }).catch((error) => {
      console.error('There was an error fetching the cart', error);
    });
  }

  /**
   * Adds a product to the current cart in session
   * https://commercejs.com/docs/sdk/cart/#add-to-cart
   *
   * @param {string} productId The ID of the product being added
   * @param {number} quantity The quantity of the product being added
   */
  handleAddToCart(productId, quantity, variant) {
    commerce.cart.add(productId, quantity,variant).then((item) => {
      this.setState({ cart: item.cart })
    }).catch((error) => {
      console.error('There was an error adding the item to the cart', error);
    });
  }

  /**
   * Updates line_items in cart
   * https://commercejs.com/docs/sdk/cart/#update-cart
   *
   * @param {string} lineItemId ID of the cart line item being updated
   * @param {number} quantity New line item quantity to update
   */
  handleUpdateCartQty(lineItemId, quantity) {
    commerce.cart.update(lineItemId, { quantity }).then((resp) => {
      this.setState({ cart: resp.cart })
    }).catch((error) => {
      console.log('There was an error updating the cart items', error);
    });
  }

  /**
   * Removes line item from cart
   * https://commercejs.com/docs/sdk/cart/#remove-from-cart
   *
   * @param {string} lineItemId ID of the line item being removed
   */
  handleRemoveFromCart(lineItemId) {
    commerce.cart.remove(lineItemId).then((resp) => {
      this.setState({
        cart: resp.cart
      })
    }).catch((error) => {
      console.error('There was an error removing the item from the cart', error);
    });
  }

  /**
   * Empties cart contents
   * https://commercejs.com/docs/sdk/cart/#remove-from-cart
   */
  handleEmptyCart() {
    commerce.cart.empty().then((resp) => {
      this.setState({ cart: resp.cart })
    }).catch((error) => {
      console.error('There was an error emptying the cart', error);
    });
  }

  /**
   * Captures the checkout
   * https://commercejs.com/docs/sdk/checkout#capture-order
   *
   * @param {string} checkoutTokenId The ID of the checkout token
   * @param {object} newOrder The new order object data
   */
  handleCaptureCheckout(checkoutTokenId, newOrder) {
    commerce.checkout.capture(checkoutTokenId, newOrder).then((order) => {
      this.setState({
        order: order,
      });
      // Store the order in session storage so we can show it again
      // if the user refreshes the page!
      window.localStorage.setItem('order_receipt', JSON.stringify(order));
      // Clears the cart
      this.refreshCart();
      // Send the user to the receipt
      this.props.history.push('/confirmation');
    }).catch((error) => {
      console.log('There was an error confirming your order', error);
    });
  }

  /**
   * Refreshes to a new cart
   * https://commercejs.com/docs/sdk/cart#refresh-cart
   */
  refreshCart() {
    commerce.cart.refresh().then((newCart) => {
      this.setState({
        cart: newCart,
      })
    }).catch((error) => {
      console.log('There was an error refreshing your cart', error);
    });
  }

  renderNavBar() {
    return (
      <NavigationBar
        {...this.props}
        cart={this.state.cart}
        onUpdateCartQty={this.handleUpdateCartQty}
        onRemoveFromCart={this.handleRemoveFromCart}
        onEmptyCart={this.handleEmptyCart}
        categories={this.state.categories}
      />
    );
  }

  render() {
    const {
      products,
      merchant,
      cart,
      categories,
      order
    } = this.state;
    return (
      <div className="app">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => {
              return (
                <>
                  {this.renderNavBar()}
                  <div className="dividedpage">
                    <SideNavBar></SideNavBar>
                  <div>
                  <Hero
                    merchant={merchant}
                  />
                  <CategoryList
                    {...props}
                    categories={categories}
                  />
                  </div>
                  </div>
                </>
              );
            }}
          />
          <Route
            path="/all"
            exact
            render={(props) => {
              return (
                <>
                  {this.renderNavBar()}
                  <div className="dividedpage">
                    <SideNavBar></SideNavBar>
                  <div>
                  <div className="category">
                  <h2 className="caption-lg ml-5 pl-2">ALL</h2>
                  <ProductsList
                    {...props}
                    products={products}
                    onAddToCart={this.handleAddToCart}
                  />
                  </div>
                  </div>
                  </div>
                </>
              );
            }}
          />
          <Route
            path="/checkout"
            exact
            render={(props) => {
              return (
                <Checkout
                  {...props}
                  cart={cart}
                  onCaptureCheckout={this.handleCaptureCheckout}
                />
              )
            }}
          />
          <Route
            path="/confirmation"
            exact
            render={(props) => {
              return (
                <Confirmation
                  {...props}
                  order={order}
                  onBackToHome={() => window.localStorage.removeItem('order_receipt')}
                />
              )
            }}
          />
           <Route
            path="/cart"
            exact
            render={(props) => {
              return (
                <>
                 {this.renderNavBar()}
                  <div className="dividedpage">
                    <SideNavBar></SideNavBar>
                  <div>
                  <div className="category">
                    <CartPage
                      {...props}
                      cart={cart}
                      onUpdateCartQty={this.handleUpdateCartQty}
                      onRemoveFromCart={this.handleRemoveFromCart}
                      onEmptyCart={this.handleEmptyCart} />
                  </div>
                  </div>
                  </div>
                </>
              )
            }}
          />
          <Route
            path="/categories/:slug"
            exact
            render={(props) => {
              return (
                <>
                  {this.renderNavBar()}
                  <div className="dividedpage">
                    <SideNavBar></SideNavBar>
                  <div>
                  <Category
                    {...props}
                  />
                  </div>
                  </div>
                </>
              )
            }}
          />
          <Route
            path="/item/:permalink"
            exact
            render={(props) => {
              return (
                <>
                  {this.renderNavBar()}
                  <div className="dividedpage">
                    <SideNavBar></SideNavBar>
                  <div>
                  <Item
                    {...props}
                    onAddToCart={this.handleAddToCart}
                  />
                   </div>
                  </div>
                </>
              )
            }}
          />
        </Switch>
      </div>
    );
  }
};

export default withRouter(App);

App.propTypes = {
  history: PropTypes.object,
};
