import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CategoryList extends Component {

    render() {
        const { categories } = this.props;

        return (
            <>
                <div className="products" id="products">
                    {categories.map((category) => (
                        <>
                            <div className="section-separator"></div>
                            <div className="product-name-price-list">
                                <a href={`/#/categories/${category.slug}`}>
                                    <h4 className="title-4">{category.name}</h4>
                                </a>
                            </div>
                        </>
                    ))}
                </div>
            </>
        )
    }
}

export default CategoryList;

CategoryList.propTypes = {
    category: PropTypes.array,
};