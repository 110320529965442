import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartItem';
import { Link } from "react-router-dom";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyClicked: false
    };
    this.handleEmptyCart = this.handleEmptyCart.bind(this);
  }

  _isMounted = false

  handleEmptyCart() {
    this.props.onEmptyCart();
  }

  renderEmptyCart() {
    const { cart } = this.props;
    if (cart.total_unique_items > 0) {
      return;
    }

    return (
      <p className="cart__none">
        You have no items in your shopping cart, start adding some!
      </p>
    );
  }

  handleShow = () => {
    this.setState({ emptyClicked: true });
  };

  handleHide = () => {
    this.setState({ emptyClicked: false });
  };

  renderCart() {
    const { cart } = this.props;
    if (cart.total_unique_items === 0) {
      return;
    }
    if (this._isMounted) { 
      return (
        <>
          {cart.line_items.map(lineItem => (
            <CartItem
              item={lineItem}
              key={lineItem.id}
              {...this.props}
            />
          ))}
          <div className="">
            <div className="title-4">Subtotal:</div>
            <div className="title-4">{cart.subtotal.formatted_with_symbol}</div>
          </div>
            <div className="button-mobile-width">
              
              <Link
                  className="primary-btn"
                  to="/checkout"
                >
                  Checkout
              </Link>
              <button type="button" className="secondary-btn" onClick={this.handleEmptyCart}>Empty cart</button>
            </div>
        </>
      );
    }
    else {
      return (
        <div></div>
      );
    }
  }

  render() {
    return (
        <div className="cart">
          <h4 className="title margin-left-sm">Your Shopping Cart</h4>
          { this.renderEmptyCart()}
          { this.renderCart()}
        </div>
    );
  };
};

export default Cart;

Cart.propTypes = {
  cart: PropTypes.object,
  onUpdateCartQty: () => { },
  onRemoveFromCart: () => { },
  onEmptyCart: () => { },
  handleUpdateCartQty: PropTypes.func,
  history: PropTypes.object
};
