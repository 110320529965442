import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { commerce } from '../lib/Commerce';
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';

class Item extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            qty: 1,
            modal: false,
            imgSrc: 0,
            option: {},
            vari: {},
            price: ''
        }
        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.fetchProducts();
    };

    hasVariants = false
    _isMounted = false
    list = [];
    variantOption = {};
    optionNames = {};
    currentOptions = {};
    variantPrices = {};

    componentDidMount() {
        //  if (!this._isMounted)
        this.fetchProducts();
    }

    toggleModal = e => {
        this.setState({ modal: !this.state.modal });
    };

    fetchProducts() {
        commerce.products.retrieve(this.props.match.params.permalink, { type: "permalink", }).then((result) => {
            this.setState({ product: result });
            this._isMounted = true
            this.hasVariants = result.variants.length > 0;
            this.list = [[],[],[],[],[],[]];
            if (this.hasVariants) {
                {
                    var i = 0
                    var j = 0
                    var x = 0;
                    for (let variant of result.variants){
                        for (let opt of variant.options){
                            if(opt.quantity>0){
                                this.list[i].push({ key: variant.id+i, text: opt.name, value: opt.id })
                                if(x==0){
                                    this.variantOption[variant.id]=opt.id
                                    x++
                                    this.setState({ price: opt.price.formatted_with_symbol });
                                    this.currentOptions[variant.id] = opt.name
                                }
                               
                                this.optionNames[opt.id]=opt.name
                                this.variantPrices[opt.id]=opt.price.formatted_with_symbol
                            }
                            j++
                        }
                        x=0
                        i++
                        j=0
                    }
                    
                }
            }
            else{
                this.setState({ price: result.price.formatted_with_symbol });
            }
        });
    }

    handleAddToCart() {
        if(this.hasVariants){
            //const variant = { [this.variantOption.variant]: this.variantOption.option };
            this.props.onAddToCart(this.state.product.id, this.state.qty, this.variantOption);
        }
         else
             this.props.onAddToCart(this.state.product.id, this.state.qty);
        this.setState({ modal: true });
    }

    getOptions = (number) =>
        _.times(number, (index) => ({
            key: index,
            text: `${index + 1}`,
            value: index + 1,
        }))

    handleDropdown = (event, data) => {
        this.setState({ qty: data.value });
    };

    handleDropdownVarient = (event, data) => {
        var length =data.options[0].key.length
        this.currentOptions[data.options[0].key.substring(0, length-1)] = this.optionNames[data.value]
        this.variantOption[data.options[0].key.substring(0, length-1)]=data.value
        this.setState({ price:  this.variantPrices[data.value] });
        console.log(data)
        console.log( this.list[0])
    };

    handleMouseOver = (index) => {
        this.setState({
            imgSrc: index
        });
    }

    checkQuanity(){
        var p = this.state.product
        if(p.variants.length>0)
        {
            for (var i = 0;i<p.variants[0].options.length;i++){
                if(p.variants[0].options[i].quantity>0)
                    return 1
            }
            return 0;
        } else
            return this.state.product.quantity;
    }

    render(props) {
        const {
            imgSrc,
            product,
            price
        } = this.state;
        if (this._isMounted) {
            const hasMultiPhotos = product.assets.length > 1;
            const quantity = this.checkQuanity()
            return (
                <div className="category">
                    <div className="product-page">
                        <div>
                            {product.assets.map((asset, index) => (
                                <img className="product-container-item-page" src={asset.url} alt={product.name} hidden={imgSrc !== index} />
                            ))}
                            {hasMultiPhotos ? (<div className="thumbnail-container">
                                {product.assets.map((asset, index) => (
                                    <img className="thumbnail" src={asset.url} alt={product.name} width='100' height='100'
                                        onMouseEnter={() => this.handleMouseOver(index)}
                                    />
                                ))}
                            </div>
                            ) : <></>}
                        </div>
                        
                        <div className="product-info">
                            <div className="product-name">
                                {product.name}
                                <p className="caption-sm">
                                    {price}
                                </p>
                            </div>
                            <div className="mb-4" dangerouslySetInnerHTML={{ __html: product.description }}></div>
                            {quantity>0 ? (   
                            <div>
                            <div className="varient">
                                {product.variants.map((variant, index) => (
                                    <div>
                                        <div>{variant.name}</div>
                                                <Dropdown
                                                        selection
                                                        defaultValue={this.list[index][0].value}
                                                        placeholder={'Select a ' + variant.name}
                                                        scrolling options={ this.list[index]}
                                                        onChange={this.handleDropdownVarient}
                                                        className="dropdown-mobile-width title-4" />
                                    </div>
                                ))}
                                <div className="dropdown">Qty: </div>
                                <Dropdown
                                    selection
                                    defaultValue={1}
                                    scrolling options={this.getOptions(99)}
                                    onChange={this.handleDropdown}
                                    className="dropdown-mobile-width" />

                            </div>
                            <button
                                name="Add to cart"
                                className="primary-btn dropdown-mobile-width mb-4"
                                onClick={() => this.handleAddToCart()}>
                                Add to cart
                            </button>
                            </div>
                            ) : <> <div className="dropdown">OUT OF STOCK</div></>}
                        </div>
                        


                    </div>
                    <Modal isOpen={this.state.modal} handleclose={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>
                           <div className="title-4">ITEM ADDED</div>
                            </ModalHeader>
                        <ModalBody>
                            <div className="row product">
                                <div className="">
                                    <img src={product.media.source} alt={product.name} height="150" />
                                </div>
                                <div className="added-item">
                                    <div className="caption-lg">{product.name}</div>
                                    <div className="caption-sm mb-3">{price}</div>
                                    <div className="d-flex align-items-center quantity-modal">
                                        <div className="body">Qty:</div>
                                        <div className="badge margin-left-xs"> {this.state.qty} </div>
                                    </div>
                                    {product.variants.map((variant, index) => (
                                        <div className="d-flex align-items-center quantity-modal">
                                            <div className="body">{variant.name}:</div>
                                            <div className="badge margin-left-xs"> {this.currentOptions[variant.id]} </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-buttons mt-5">
                                <button
                                    className="cart__btn-secondary button-mobile-width"
                                    onClick={this.toggleModal}>Continue Shopping</button>
                                <Link
                                    className="primary-btn button-mobile-width"
                                    to="/cart">
                                    Go to cart
                                </Link>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    };
};

export default Item;

Item.propTypes = {
    handleAddToCart: PropTypes.func,
    onAddToCart: PropTypes.func,
};